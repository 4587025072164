import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _24b554ba = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _127d6171 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2a9d4f34 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _4974a11a = () => interopDefault(import('../pages/search/documents.vue' /* webpackChunkName: "pages/search/documents" */))
const _44ff58f2 = () => interopDefault(import('../pages/search/products.vue' /* webpackChunkName: "pages/search/products" */))
const _34dcdb19 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2822974a = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _11c93f7d = () => interopDefault(import('../pages/documents/_slug/index.vue' /* webpackChunkName: "pages/documents/_slug/index" */))
const _50f7fcd2 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _79738db1 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _332639d1 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _24b554ba,
    name: "catalog___en"
  }, {
    path: "/search",
    component: _127d6171,
    children: [{
      path: "",
      component: _2a9d4f34,
      name: "search___en"
    }, {
      path: "documents",
      component: _4974a11a,
      name: "search-documents___en"
    }, {
      path: "products",
      component: _44ff58f2,
      name: "search-products___en"
    }]
  }, {
    path: "/",
    component: _34dcdb19,
    name: "index___en"
  }, {
    path: "/catalog/:slug",
    component: _2822974a,
    name: "catalog-slug___en"
  }, {
    path: "/documents/:slug",
    component: _11c93f7d,
    name: "documents-slug___en"
  }, {
    path: "/news/:slug?",
    component: _50f7fcd2,
    name: "news-slug___en"
  }, {
    path: "/product/:slug?",
    component: _79738db1,
    name: "product-slug___en"
  }, {
    path: "/:slug",
    component: _332639d1,
    name: "slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
